<template>
  <div class="text-center">
    <b-modal
      v-if="hasSelectedReport"
      size="md"
      class="text-center"
      :id="`executive-report-${selectedExReport.id}-sign-modal`"
      ok-only
      centered
      hide-footer
      no-close-on-backdrop
      @close="dataPushAction('closeExecutiveReportSignModal', 'closeExecutiveReportSignModal')"
      @shown="dataPushAction('openExecutiveReportSignModal', 'openExecutiveReportSignModal')"
    >
      <div class='' v-if="!selectedExReport.status">
        <b-overlay :show="isSigningER" rounded="sm">
          <!-- <b-alert class="p-1 vertical" show variant="success">
            <p class="">Executive Report now avaiable</p>
          </b-alert> -->
          <div class="p-1 mb-2" :class="selectedExReport.status ? 'signed-box rounded' : 'sign-box rounded'">
            <b-row>
              <b-col cols="12">
                <h3 class="title-report">{{$t('Executive Report')}} {{ selectedExReport.year }} </h3>
                <h5> {{ `${contractSelected?.resortsname} | ${contractSelected?.housingnumber}` }} </h5>
                <h6 class="title-membership"> {{ contractSelected.membershipcode }} </h6>

              </b-col>
            </b-row>

            <hr class="mb-0 pt-0">

            <div class="result-info mt-1">
              <p class="bold-text">{{$t('net result')}}:</p>
              <p class="bold-text" :class="`mt-1 ${selectedExReport.profit > 0 ? '' : 'red-text'}`"> {{ formatMoney({value: selectedExReport.profit, code: 'USD'}) }} USD </p>
            </div>

            <p class="pl-1 small-text" v-if="selectedExReport.profit < 0 && !selectedExReport.status">{{$t('sign negative balance')}}</p>

            <div v-if="selectedExReport.profit > 0">
              <hr>

              <p class="bold-text">{{ selectedExReport.status ? $t('cashback option selected') : $t('Choose a cashback option')}}</p>

              <div class="options-radio mb-1">
                <b-form-radio-group
                  v-model="selectedExReport.cashbackType"
                  :disabled="selectedExReport.status"
                  :options="options"
                  value-field="item"
                  text-field="name"
                  disabled-field="notEnabled"
                />
              </div>
            </div>

            <hr>

            <b-row class="" align-v="center">
              <b-col cols="12" v-if="!selectedExReport.status">
                <div class="pl-1" >
                  {{$t('I reviewed and accept')}} <b-link :to="{name: 'termsConditions', params: { term: 25, lang: user.languageId } }" target="_blank"> {{ $t('terms of use') }}</b-link>.
                </div>
              </b-col>
              <b-col md="" class="text-center mt-3 mb-1" >
                <b-button
                  :variant="selectedExReport.status ? 'info' : 'success'"
                  class=""
                  @click="signReport"
                  :disabled="(!selectedExReport.cashbackType && selectedExReport.cashbackType != 0) || selectedExReport.status || isSigningER"
                > {{selectedExReport.status ? $t('Signed') : isSigningER ? $t('Signing Report ') : $t('Sign')}}
                </b-button>

              </b-col>
              <b-col md="12" class="text-center p-50" v-if="selectedExReport.status">
                <label v-html="signedBy" class="mr-1 capitalize"></label>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </div>

      <div class="p-1" no-body v-else-if="selectedExReport && selectedExReport.status">
        <b-alert class="p-1 vertical" show variant="success" dismissible>
          <p class="">{{$t('year report signed', {year: selectedExReport.year})}}</p>
        </b-alert>
      </div>

      <div class="p-1" v-else>
        <b-alert class="p-1 vertical" show variant="warning">
          <p class="">{{$t('Executive Report not avaiable')}}</p>
        </b-alert>
      </div>
    </b-modal>

    <b-card class="signed-box border rounded mt-3" v-if="reportList.length > 0">
      <div id="executive-reports-docs" class="overflow">
        <b-table
          :items="reportList"
          :fields="fields"
          responsive
          class="m-0"
          small
          striped
          show-empty
          sticky-header="25rem"
          empty-text="Not avaiable reports"
        >
          <template #cell(name)="row">
            <span class="">
              <feather-icon icon="FileTextIcon" size="18" /> <span class="font-weight-bold">{{ $t('Executive Report') }}</span> <span class="font-weight-bolder">{{ row.item.year }}</span>
            </span>
          </template>

          <template #cell(profit)="row">
            <span :class="row.item.profit < 0  ? 'red-text' : ''">
              {{formatMoney({value: row.item.profit, code: 'USD'})}} USD

            </span>
          </template>

          <template #cell(status)="data">
              <b-badge :variant="!data.item.status ? 'info' : 'success'">{{ data.item.status ? $t('Signed') : $t('Sign') }}</b-badge>
            </template>

          <template #cell(file)="data">
            <div class="text-center">
              <b-button class="text-nowrap text-left margin-quart vertical" size="sm" variant="primary" target="_blank" rel="noopener noreferrer"
                @click="downloadReport(data)"
              > <feather-icon icon="DownloadIcon" size="14" /> {{$t('Download')}}
              </b-button>
              <b-button class="text-nowrap text-left margin-quart vertical"  size="sm" variant="primary" @click="openSignModal(data.item)" :disabled="!data.item.current || data.item.status">
                <feather-icon :icon="data.item.status ? 'CheckIcon' : 'Edit2Icon'" size="14" /> {{ data.item.status ? $t('Signed') : $t('Sign') }}
              </b-button>
            </div>
          </template>

        </b-table>
      </div>
    </b-card>

    <b-card v-else-if="isLoadingExReport" class="text-center mt-3">
      <b-spinner/> <br> {{ $t('loading') }}
    </b-card>

    <b-card v-else class="text-center">
      <b-alert class="p-1 vertical" show variant="warning">
        <p class="">{{$t('Executive Report not avaiable')}}</p>
      </b-alert>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { showAlertMessage, makeTime, formatDate, toCurrency, toJson} from '@/helpers/helpers'
import { utils } from "@/modules/owners/mixins/utils"

export default {
  mixins: [ utils ],
  async created(){
    await this.checkExReps()
    if (!this.selectedExReport) this.setExResp()
  },
  data(){
    return {
      options: [
        { item: 2, name: 'Cash' },
        { item: 37, name: 'Resort Credit' }
      ],
      fields: [
        // {key: 'year', label: this.$t('year')},
        {key: 'contract', label: this.$t('contract')},
        {key: 'dateInit', label: this.$t('dateinit')},
        {key: 'dateEnd', label: this.$t('dateend')},
        {key: 'membership', label: this.$t('membership')},
        {key: 'name', label: this.$t('name')},
        {key: 'cashbackType', label: this.$t('cashback type'), formatter: value => {
          return this.options.find(item => item.item == value)?.name || '-'
        }},
        {key: 'signedBy', label: this.$t('signed by'), formatter: value => {
          return value || '-'
        }},
        {key: 'signedAt', label: this.$t('signed at'), formatter: value => {
          return value || '-'
        }},
        //{key: 'profit', label: this.$t('net result'),  },
        // {key: 'status', label: this.$t('Status')},
        {key: 'file', label: this.$t('Actions')}
      ],
      isSigningER: false,
      anualReport: null,
      exReports: [],
      authURL: process.env.VUE_APP_IMG_SRC_API,
      selectedExReport: null,
      currentYearToSign: null,
      isLoadingExReport: false
    }
  },
  watch: {
    myContracts: {
    deep: true,
    async handler() {
      await this.checkExReps()
      this.setExResp()
    },
    },
  },
  computed:{
    ...mapState('owners', ['profits']),
    ...mapState('auth', ['user','myContracts','userAdmin']),
    ...mapGetters('auth', ['contractSelected']),
    signedBy(){
      return this.$t('Signed by', {user: this.selectedExReport.signedBy, date: this.selectedExReport.signedAt} )
    },
    hasSelectedReport(){
      return !!this.selectedExReport
    },
    reportList(){
      return this.exReports.sort((a, b) => b.year - a.year)
    }
  },
  methods:{
    ...mapActions('owners', ['fetchMyProfits','signExecutiveReport','pushActionsService', 'getExecutiveReportFile']),
    ...mapMutations('owners',['setAnualProfit']),
    formatMoney(data){
      const {value, code} = data
      return toCurrency(value, code)
    },
    async signReport(){
      this.$root.$emit('bv::hide::modal', `executive-report-${this.selectedExReport.id}-sign-modal`)

      // validar si es admin desde mixin en utils
      if( this.isAdmin ){
        return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
      }
      const cashback = this.options.find(item => item.item == this.selectedExReport.cashbackType)?.name

      const { isConfirmed } = await this.$swal.fire({
        title: this.$t('Signing executive report'),
        text: `${this.$t('By signing the following report...')}${this.selectedExReport.profit > 0 ? this.$t('...And get', {cashback}) : '.'}`,
        icon: 'info',
        showDenyButton: true,
        confirmButtonText: this.$t('Sign'),
        denyButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'mr-1',
        }
      })
      if (isConfirmed){
        this.isSigningER = true
        const { id } = this.contractSelected
        const response = await this.signExecutiveReport({
          idOwner: this.user?.idOwner,
          idContract: id,
          idContractPerformance: this.selectedExReport.id,
          cashbackType: this.selectedExReport.cashbackType
        })
        if (response) {
          this.selectedExReport.cashbackType = null
          showAlertMessage(this.$t('Report Signed'), 'CheckIcon', this.$t('Thank you so much'), 'success', 4000, 'bottom-right')
          await this.checkExReps()
          await this.pushData('onClick', 'signReport', 'signReport', this.$route.name ) // push a back del registro
          this.setExResp()
        } else showAlertMessage(this.$t('Report not signed'), 'CheckIcon', this.$t('Sorry, please try again later'), 'warning', 4000, 'bottom-right')
          this.isSigningER = false
      }
    },
    async checkExReps(){
      this.isLoadingExReport = true
      const { id } = this.contractSelected // id del contrado seleccionado
      const anualProfit = await this.fetchMyProfits({idContract:id, isAnual: 'True', year: (new Date().getFullYear() - 1)}) // TODO: cambiar con el id del contracto seleccionado
      this.setAnualProfit(anualProfit)
      this.isLoadingExReport = false
    },
    setExResp(){
      this.exReports = []
      this.anualReport = this.profits?.anual || []
      if (this.anualReport?.length > 0) this.anualReport.forEach(anualReport => {
        this.exReports.push({
          contract:anualReport.contract,
          membership:anualReport.membership,
          dateInit:this.formDate(anualReport.dateInit),
          dateEnd:this.formDate(anualReport.dateEnd),
          current: anualReport.currentSigned,
          legacy: anualReport.executiveReport,
          id: anualReport?.id,
          year: anualReport.year,
          name: anualReport?.executiveReport?.typename,
          status: anualReport?.authorized,
          file: anualReport?.executiveReport?.fileRoute ? process.env.VUE_APP_IMG_SRC_API + anualReport?.executiveReport?.fileRoute : null,
          filename: anualReport?.executiveReport?.name,
          profit: anualReport?.remainingBalance,
          cashbackType: anualReport?.choicedBenefit || (anualReport?.remainingBalance <= 0 ? 0 : null),
          signedBy: anualReport?.authorizedBy,
          signedAt: anualReport?.authorizeddate ? this.formDate(anualReport?.authorizeddate) : ''
        })
      })

    },
    formDate(date){
      return formatDate({date, language: this.user?.languageName})
    },
    async openSignModal(executiveReport){
      this.selectedExReport = toJson(executiveReport)
      await makeTime(400)
      this.$root.$emit('bv::show::modal', `executive-report-${executiveReport.id}-sign-modal`)
    },
    async downloadReport(data){
      const nameFile = data.item.legacy ? data.item.filename : `Executive Report ${data.item.year}`
      const fullPath = data.item.legacy ? `${data.value}` : `${this.authURL}FivesClub/getExecutiveReportFile/${data.item.contract}/${this.user?.idOwner}/${this.contractSelected.housingid}/${data.item.year}`
      window.open(fullPath, '_blank');
      await this.pushData('onClick', 'downloadFile', 'downloadFile', this.$route.name, nameFile ) // push a back del registro
    },
    async dataPushAction(action, aditionalInfo){
			await this.pushData('onClick', 'intentToSign', 'intentToSign', this.$route.name ) // push a back del registro
    },
  }
}
</script>

<style scoped>
.vertical{
  vertical-align: middle;
}
.title {
  font-weight: 500;
  font-size: 1.285rem;
  vertical-align: middle !important;
}
.custom-margin-top{
  margin-top: 0.5rem;
}

.overflow{
  max-height: 17rem;
  overflow-y: scroll;
}

.marginr{
  margin-right: 0.5rem;
}
.margin-half{
  margin: 0.5rem;
}
.margin-quart{
  margin: 0.25rem;
}
.transparent{
  color: transparent;
}
.box-report{
  max-width: 51rem;
}
.box-report-noprofit{
  max-width: 36rem;
}
.sign-box{
  border: 1px solid #cdeddb !important;
  transition: all 0.5s ease;
}
.sign-box:hover{
  border: 1px solid #28c76f !important;
  transition: all 0.5s ease;
}
.signed-box{
  border: 1px solid #dff4f5 !important;
  transition: all 0.5s ease;
}
.signed-box:hover{
  border: 1px solid #5a5a5a !important;
  transition: all 0.5s ease;
}
.capitalize{
  text-transform: capitalize;
}

.p-50{
  padding: 0 50px !important;
}
.title-membership{
  font-size: .9rem;
}
.result-info {
  display: flex;
  justify-content: space-between;
}
.bold-text{
  font-weight: bold;
}

.red-text{
  color: rgb(216, 40, 40);
}
.options-radio {
  display: flex;
  justify-content: center;
}
.small-text{
  font-size: smaller;
}
a {
  text-decoration: underline;
}
</style>