<template>
  <div>
      <!-- <h4>{{ $t('condo fee') }}</h4> -->

      <b-row align-v="end">
        <b-col>
          <label>{{$t('Search by year')}}</label>
          <v-select
            v-model="selectedYear"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="yearsList"
            :clearable="false"
            class="per-page-selector w-custom-cc"
            :placeholder="$t('Select a year')"
            @input="getcondominalfees"
          />
        </b-col>

        <!-- <b-form-group label="Numero de registros" v-show="condominiumFees.length > 0">
          <v-select
            v-model="porPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            @input="changePerPage"
          />
        </b-form-group>

        <b-form-group label="Buscar en tabla" v-show="condominiumFees.length > 0" >
          <b-form-input
            v-model="queryHousing"
            placeholder="Buscar..."
            @input="setFilter"
            class="input-search-t"
          />
        </b-form-group> -->

        <b-col cols="6">
          <b-button
            class="float-right"
            :href="`${authURL}FivesClub/accountstatement/${myContracts.contractSelected.housingid}/${selectedYear}/`"
            target="_blank"
            rel="noopener noreferrer"
            variant="primary"
          > 
          <feather-icon icon="DownloadIcon" size="14" /> {{ $t('Download') }}
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mt-2 mb-1">
        <b-col cols="" class="d-flex justify-content-between align-items-center">
          <div>
            <span class="bold">{{$t('Anual amount')}}: </span> {{formatMoney({value: condominiumFees.anual, code: 'USD'})}} <br>
            <span class="bold">{{$t('Periodicity')}}: </span> {{condominiumFees.periodicity}} <br>
            <span class="bold">{{$t('Currency')}}: </span> {{condominiumFees.currency}}
          </div>
        </b-col>
        <b-col>
          <span class="bold">{{$t('Past Due')}}: </span> {{formatMoney({value: condominiumFees.saldo, code: 'USD'})}} <br>
          <!-- <span class="bold">Positive Balance: </span> {{formatMoney({value: condominiumFees.positive, code: 'USD'})}} -->
        </b-col>
      </b-row>

      <b-table
        :fields="fieldsFees"
        :items="condominiumFeesDetails"
        class="mb-0 "
        show-empty
        responsive
        empty-text="No hay registros para mostrar"
        :busy.sync="isLoadingData"
        :tbody-tr-class="rowClass"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" label="Loading..." variant="success"></b-spinner>
            <strong> {{$t('loading')}}... </strong>
          </div>
        </template>
        <template #cell(operationdate)="row">
          <div class="">
            <!-- <span :class="`${highlightSubtype(row.item.subtype) ? 'font-weight-bolder' : ''}`"> {{ formatThisDate(row.item.period.startDate) }} </span> -->
            <span :class="`${highlightSubtype(row.item.subtype) ? 'font-weight-bolder' : ''}`" v-if="row.item.subtype == 1"> {{ formatThisDate(row.item.period.startDate) }} </span>
            <span :class="`${highlightSubtype(row.item.subtype) ? 'font-weight-bolder' : ''}`" v-else> {{ formatThisDate(row.item.operationdate) }}  </span>
          </div>
        </template>
        <template #cell(subtypeName)="row">
          <div class="subtype-text">
            <span :class="`${highlightSubtype(row.item.subtype) ? 'font-weight-bolder' : ''}`"> 
              {{row.item.subtypeName}}
              <template v-if="row.item.subtype == 1">{{row.item.period.periodName}}</template>
              <template v-if="row.item.subtype == 6">{{ $t('from previous year') }}</template>
              <template v-if="row.item.subtype == 2">{{ $t('condominal') }}</template>
            </span>
          </div>
        </template>
        <template #cell(quantity)="row">
          <div class="">
            <span :class="`${highlightSubtype(row.item.subtype) ? 'font-weight-bolder' : ''}`"> {{formatMoney({value: row.item.quantity, code: 'USD'})}} </span>
          </div>
        </template>
      </b-table>

      <PaginationTable
        class="mt-1"
        v-show="condominalDetails > 12"
        :currentPage="currentPage"
        :totalRows="condominalDetails"
        :perPage="porPage"
        @change-page="changePage"
      />

  </div>
</template>

<script>

import { mapState, mapActions, mapMutations } from "vuex"
import { showAlertMessage, toCurrency, formatDate } from '@/helpers/helpers'
import PaginationTable from '@/modules/owners/components/myProfits/PaginationTable'


import vSelect from 'vue-select'

export default {
  props:{
    condominiumFees: {
      type: Object,
      required: true,
    },
    isLoadingData: {
      type: Boolean,
      required: true,
    }
  },
  components: { vSelect, PaginationTable },
  data(){
    return {
      porPage: 12,
      // perPage: 10,
      currentPage: 1,
      perPageOptions: [1, 6, 12, 18, 36, 60, 90, 120],
      fieldsFees: [
        { key:'operationdate', label: this.$t('date'), class:'text-justify w-25', thStyle: { width: "200px" }},
        { key:'subtypeName', label: this.$t('Concept'), class:'w-50'},
        { key:'quantity', label: this.$t('Amount'), class:'w-25'},
      ],
      fieldsDetailFee:[
        { key:'paymentdate', label:'Fecha de pago' },
        { key:'paymentmethod', label:'Forma de pago' },
        { key:'userName', label:'Usuario' },
        { key:'subtype', label:'Movimiento'},
        { key:'quantity', label:'Monto' },
        { key:'notes', label:' Notas'}
      ],
      queryHousing: null,
      selectedYear: new Date().getFullYear(),
      feeHousingSelected: null,
      authURL: process.env.VUE_APP_IMG_SRC_API,
    }
  },
  computed:{
    ...mapState('start', ['hotels']),
    ...mapState('auth', ['user', 'myContracts']),
    condominiumFeesDetails(){
      if (this.condominiumFees?.details?.length) return this.condominiumFees?.details?.slice( (this.currentPage - 1) * this.porPage, this.currentPage * this.porPage )
    },
    yearsList(){
      const years = []
      const currentYear = new Date().getFullYear()
      for (let i = currentYear - 2; i < currentYear + 1; i++) {
        years.push(i + 1)
      }
      return years
    },
    idHousing() {
      return this.$route.params.id
    },
    condominalDetails(){
      return this.condominiumFees?.details?.length || 0
    }
  },
  methods:{
    ...mapMutations('owners',['setSelectedFeeHousing','setCondominiumFeeSelected','setIsLoadingDetailsCondominiumFee', 'setDetailsCondominiumFee']),
    formatMoney(data){
      const {value, code} = data
      return toCurrency(value, code)
    },
    formatThisDate(data){
      return formatDate({date: data, toFormat:'DD'+'/'+'MM'+'/'+'YYYY'})
    },
    changePerPage( perPage ){
      this.perPage = perPage
    },
    changePage(page){
      this.currentPage = page
    },
    setFilter(e){
      console.log(e)
    },
    colorQuarter(item){
      const colors = { 1: 'quarter-one',2 : 'quarter-second', 3 : 'quarter-third' }
      return colors[item.quarter]
    },
    async getcondominalfees(){
      this.$emit('get-condominal-fees', this.selectedYear)
    },
    highlightSubtype(subtype){
      return [1, 6].includes(subtype)
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      // if (item.type == 2) return 'table-danger'
      // if (item.type == 3) return 'table-warning'
    },
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
.capitalize-text{
text-transform: capitalize;
}
.subtype-text{
text-transform: lowercase !important;
}
.subtype-text::first-letter{
text-transform: capitalize;
}
.actions-filter{
  display: flex;
  flex-direction: row;
  /* justify-content: space-between;  */
  gap: 1.5rem;
}
.input-search-t{
  min-width: 240px;
  width: 350px;
}
.edit-h{
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.quarter-one{
  color: #14142B ;
}

.quarter-second{
  color: #610BEF;
}

.quarter-third{
  color: #005BD4;
}

.bold{
  font-weight: bold;
}

.w-custom-cc{
  max-width: 15rem;
}
</style>